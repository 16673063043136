.app {
  font-size: 15px;
  color: #fff;
  background-color: var(--white);
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: rgb(37, 40, 127);
  background: linear-gradient(
    105deg,
    rgba(37, 40, 127, 1) 0%,
    rgba(40, 40, 126, 1) 21%,
    rgba(53, 40, 121, 1) 46%,
    rgba(117, 38, 95, 1) 75%,
    rgba(136, 37, 88, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  color: var(--main-color);
  text-align: center;
  font-size: 18px;
  font-family: var(--main-Book);
  font-weight: normal;
}
.app::after {
  content: "";
  position: fixed;
  top: -123.193359375px;
  left: -190.8359375px;
  width: 613px;
  height: 622px;
  background-size: auto;
  background-clip: content-box;
  background-image: url("../../assets/Group 10248.svg");
  background-size: cover;
  /* opacity: 1;  */
  pointer-events: none;
  z-index: 0;
}
.app::before {
  content: "";
  position: fixed;
  bottom: -150px;
  right: -150px;
  width: 505px;
  height: 505px;
  background-size: auto;
  background-clip: content-box;
  background-image: url("../../assets/Group 10249.svg");
  background-size: cover;
  z-index: 0;
  pointer-events: none;
}

.auth-button {
  background: rgb(37, 40, 127);
  background: linear-gradient(
    350deg,
    rgba(37, 40, 127, 1) 0%,
    rgba(97, 38, 103, 1) 0%,
    rgba(99, 38, 102, 1) 0%,
    rgba(71, 39, 114, 1) 0%,
    rgba(86, 39, 108, 1) 0%,
    rgba(101, 38, 102, 1) 37%,
    rgba(114, 38, 97, 1) 100%,
    rgba(136, 37, 88, 1) 100%
  );
}
