#root {
  margin: 0 auto;
  text-align: center;
  padding: 0;
  overflow: hidden;
}

.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}
.hidden-overflow {
  overflow: hidden !important;
}

.rts___tabs {
  padding: 0;
}

.rts___tab {
  margin: 0;
  position: relative;
}

.rts___nav___btn svg {
  max-width: unset;
}

.rts___btn {
  border-radius: unset;
  border: none;
}

.rts___tab:hover {
  background-color: "gray";
}

.rts___tab___selected {
  color: #000;
  position: relative;
  width: 100%;
  background: transparent;
  box-shadow: none;
}

.rts___tab___selected::after {
  background: gray;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0px;
}

.rts___nav___btn:hover {
  background-color: unset;
}

.rts___nav___btn:hover > svg {
  stroke: darkgray;
}

.rts___nav___btn > svg {
  stroke: gray;
}

@media (max-width: 991.98px) {
  .rts___tabs___container {
    padding: 0;
  }
}

@media (max-width: 767.98px) {
  .rts___tab {
    padding: 5px;
  }
}
