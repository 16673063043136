@import url("./fonts/style.css");

html,
body,
#root {
  height: 100%;
  width: 100%;
  background-color: #fff;
}
:root {
  font-family: Rubik, Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color: rgba(255, 255, 255, 0.87);

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  width: "100% !important";
  height: "100% !important";
  padding: "0 !important";
}
* {
  font-family: "Rubik";
}
body {
  width: "100%";
  height: "100%";
  background-color: #242424;
  padding: 0;
  margin: 0;
  display: flex;
  place-items: center;
  min-height: 100vh;
  margin: 0 auto;
}
